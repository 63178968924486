import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { SiteContext } from 'context/site-context';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Keyboard } from "swiper";
import { Arrow } from 'components/Icons/Icons';
import { media } from 'utils/mediaQuery';
import useMatchMedia from 'utils/matchMedia';
import "swiper/css";
import "./swiper-override.css";


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

const WrapperInner = styled.div`
  position: relative;
  height: 100%;
`

const ControlsWrapper = styled.div` 
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: none;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;

  ${media.small`
    display: flex;
  `}
`

const ControlsArrow = styled.div`
  pointer-events: auto;
  opacity: ${(props) => props.show ? 1 : 0};
`

const SimpleCarousel = ({children, titlePosCallback, initialSlide, afterInit, slideChange, navCallback, ...rest}) => {
  const [ slides, setSlides ] = useState();
  const [ hasPrev, setHasPrev ] = useState(false);
  const [ hasNext, setHasNext ] = useState(false);
  const [ prevActive, setPrevActive ] = useState(false);
  const [ nextActive, setNextActive ] = useState(false);
  const SwiperRef = useRef();
  const WrapperRef = useRef();
  const ActiveIndex = useRef();
  const TitlePositions = useRef([]);


  useEffect(()=>{
    let isDesktop = window.matchMedia('(min-width:768px)').matches;
    const containerHeight = WrapperRef.current.offsetHeight;
    const containerWidth = isDesktop ? containerHeight : window.innerWidth - 40;
    const theSlides = React.Children.map(children, (child,index) => {
      if(React.isValidElement(child)) {
        const style = {width: `${containerWidth}px`, height: `${containerHeight}px`};
        return <SwiperSlide style={style}>{React.cloneElement(child, {})}</SwiperSlide>;
      }
      return child;
    });

    setSlides(theSlides);
    return () => {
    }
  },[children]);


  useEffect(() => {
    if(navCallback) {
      navCallback({prev: hasPrev, next: hasNext})
    }
  },[hasPrev,hasNext])

  const hasPrevOrNext = (index) => {
    if(slides.length > 1) {
      if(index === 0) {
        setHasPrev(false)
      } else {
        setHasPrev(true);
      }
  
      if(index === slides.length - 1) {
        setHasNext(false);
      } else {
        setHasNext(true);
      }
    }
    
  }

  return (
    <Wrapper ref={WrapperRef} {...rest}>
      <ControlsWrapper>
        <ControlsArrow 
          className="prev-button" 
          show={hasPrev} >
          <Arrow rotated/>
        </ControlsArrow>
        <ControlsArrow 
          className="next-button" 
          show={hasNext}>
          <Arrow/>
        </ControlsArrow>
      </ControlsWrapper>
      <WrapperInner>
        {slides ? 
        <Swiper
        ref={SwiperRef}
        onAfterInit={afterInit || null}
        initialSlide={initialSlide || 0}
        slidesPerView={'auto'}
        spaceBetween={150}
        centeredSlides={true}
        modules={[Navigation, Keyboard]}
        keyboard={{
          enabled: true,
          onlyInViewport: true
        }}
        navigation={{
          prevEl: ".prev-button",
          nextEl: ".next-button"
        }}
        onImagesReady={(e) =>{
          ActiveIndex.current = e.snapIndex;
          hasPrevOrNext(ActiveIndex.current);
        }}
        onSlideChange={(e) => {
          if(slideChange) {
            slideChange(e);
          }
          
          ActiveIndex.current = e.snapIndex;
          hasPrevOrNext(ActiveIndex.current);
        }}
        style={{height:'100%'}}>
            { slides }
        </Swiper> : null 
        }
      </WrapperInner>
    </Wrapper>
    
  )
}

export default SimpleCarousel;
