import React, { useState } from 'react';
import styled from 'styled-components';
import IndexProject from 'modules/IndexProject/IndexProject';
import { media } from 'utils/mediaQuery';

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  ${media.small`
    padding-top: 43px;
  `}
`

const Index = ({nodes}) => {

  return (
    <Wrapper>
      { nodes && nodes.map((project,p) => {
        return (
          <IndexProject node={project} pIndex={p} allProjects={nodes}/>
        )
      })}
    </Wrapper>
  )
}

export default Index;
