import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTransition, animated, config } from '@react-spring/web';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import useMatchMedia from 'utils/matchMedia';
import SimpleCarousel from 'components/SimpleCarousel/SimpleCarousel';
import MenuItem from 'components/MenuItem/MenuItem';
import { Close, Arrow } from 'components/Icons/Icons';
import { type } from 'utils/type';
import { fontstack } from 'utils/fontstack';
import { media } from 'utils/mediaQuery';


const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const ImageWrapper = styled.div`
  position: relative;
  visibility: ${props => props.show ? "visible" : "hidden"};
  z-index: 2;
`

const CloseButton = styled(Close)`
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 20px;
`

const CarouselCover = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgb(var(--brand-background));
`

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const CarouselInner = styled(animated.div)`
  position: relative;
  width: 100%;
  height: calc(75%);
  z-index: 1;

  ${media.small`
    height: calc(100% - 180px);
  `}
`

const CarouselCaption = styled.div`
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${media.small`
    bottom: 0;
    height: 90px;
    align-items: center;
  `}
`

const MobileArrowLeft = styled.div`
  position: absolute;
  left: 20px;
  bottom: 12px;
  opacity: ${(props) => props.show ? 1 : 0};

  ${media.small`
    display: none;
  `}
`

const MobileArrowRight = styled.div`
  position: absolute;
  right: 20px;
  bottom: 12px;
  opacity: ${(props) => props.show ? 1 : 0};
  ${media.small`
    display: none;
  `}
`

const ZoomImageCarousel = ({
  modalState,// current state of the zoom modal: UNLOADED, LOADING, LOADED, UNLOADING
  img,          // your image, prepped for zooming
  allImages,
  projects,
  index,
  pIndex,
  shouldFade,
  unZoom,
  onUnzoom,
}) => {

  const getAccurateIndex = (p,i) => {
    let index = 0;
   for( let d = 0; d < p; d++) {
     index += projects[d].images.length
   } 

   return index + i
  }

  const getProjectIndex = (a) => {
    let projectIndex = 0;
    let imageCount = 0;

    while (projectIndex < projects.length) {
      imageCount += projects[projectIndex].images.length;
      if(a < imageCount) break;
      projectIndex++;
    }
   return projectIndex;
  }
  const isDesktop = useMatchMedia('(min-width:768px)', true)
  const [ hasPrev, setHasPrev ] = useState(false);
  const [ hasNext, setHasNext ] = useState(false);
  const [ showImage, setShowImage ] = useState(true);
  const [ showCarousel, setShowCarousel ] = useState(false);
  const [ ProjectIndex, setProjectIndex ] = useState(pIndex);
  const accurateIndex = getAccurateIndex(pIndex,index);
  const currentCarouselIndex = useRef(accurateIndex);
  const flattenedProjects = projects ? projects.reduce((acc,curr) => {
    acc = [...acc, ...curr.images];
    return acc;
  },[]) : allImages;


  const transitions = useTransition(showCarousel, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {mass:1, tension:500, friction:0, clamp: true}
  })

  useEffect(()=> {
    if(modalState === "UNLOADING" && accurateIndex === currentCarouselIndex.current) {
      if(isDesktop) {
        setShowImage(true);
      }
      
      setShowCarousel(false);
    } else if (modalState === "UNLOADING" && accurateIndex !== currentCarouselIndex.current) {
      shouldFade();
      setShowCarousel(false);
    } else if (modalState === "UNLOADED") {
      setShowImage(true);
    } else if (modalState === "LOADED") {
      setShowCarousel(true);
    } else if(modalState === "LOADING" && !isDesktop) {
      setShowImage(false);
    }
  },[modalState])

  
  const navUpdates = (state) => {
    if(state) {
      setHasPrev(state.prev)
      setHasNext(state.next)
    }
  }

  return (
    <Wrapper>
      { modalState === 'LOADED' && <CloseButton onClick={() => unZoom()}/> }
      <ImageWrapper show={showImage}>
        {img}
      </ImageWrapper>
      <CarouselWrapper>
          {transitions((style,item) => item && (
            <>
              <CarouselInner style={style}>
                <SimpleCarousel navCallback={navUpdates} initialSlide={accurateIndex} afterInit={() =>{ setTimeout(() => { setShowImage(false) }, 200) }} slideChange={(swiper) => {
                  currentCarouselIndex.current = swiper.activeIndex;
                  setProjectIndex(getProjectIndex(swiper.activeIndex));
                }}>
                  { flattenedProjects && flattenedProjects.map((node,i) => {
                    const imageObject = node.asset ? getImage(node.asset) : null; 
                    return (
                      <GatsbyImage 
                      image={imageObject}
                      alt={node.alt}
                      sizes='(max-width: 800px) 500px, (max-width: 1200px) 800px, (max-width: 1920px) 900px, (max-width: 2520px) 1000px, (min-width: 2521px) 1200px'
                      style={{ position: 'absolute', height: '100%', width: '100%'}}
                      loading={"eager"}
                      objectFit={"contain"} /> 
                    )
                  })}
                </SimpleCarousel>
              </CarouselInner>
              <CarouselCover style={style}/>
            </>
          ))}
        <CarouselCaption>
        { modalState === 'LOADED' && <><MenuItem num={projects[ProjectIndex].subtitle}>{projects[ProjectIndex].title}</MenuItem></> }
        </CarouselCaption>
        { modalState === 'LOADED' && <><MobileArrowLeft show={hasPrev} className="prev-button" ><Arrow rotated/></MobileArrowLeft>
        <MobileArrowRight show={hasNext} className="next-button" ><Arrow/></MobileArrowRight></> }
      </CarouselWrapper>
    </Wrapper>
  )
}

export default ZoomImageCarousel;
