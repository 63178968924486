import React, { useState } from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import ZoomImageCarousel from 'modules/ZoomImageCarousel/ZoomImageCarousel';
import { Controlled as Zoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const CustomImage = styled(GatsbyImage)`
  position: relative;
  width: 100%;
  height: 100%;
`

const ZoomImage = ({node, images}) => {
  const [isZoomed, setIsZoomed] = useState(false)

  const imageObject = node.asset ? getImage(node.asset) : null;
  const ExtraCustomZoomContent = React.useCallback((props) => {
    return (
      <ZoomImageCarousel {...props} setIsZoomed={setIsZoomed} images={images}/>
    )
  }, [])
 return (
  <Zoom isZoomed={isZoomed} ZoomContent={ExtraCustomZoomContent} zoomMargin={90}>
    <CustomImage 
      onClick={()=>setIsZoomed(true)}
      image={imageObject}
      backgroundColor={imageObject.backgroundColor}
      alt={node.alt}
      objectFit="cover"
      objectPosition="center"
      layout="fullWidth" /> 
  </Zoom>
 )
}

export default ZoomImage;