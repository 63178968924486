export const getStyleModalImg = ({
  isZoomed,
  shouldRefresh,
  loadedImgEl,
  targetEl,
}) => {
  
  const imgRect = targetEl.getBoundingClientRect();
  const targetElComputedStyle = window.getComputedStyle(targetEl)
  const scale = 6;
  const styleImgRegular = getImgRegularStyle({
    containerHeight: imgRect.height,
    containerLeft: imgRect.left,
    containerTop: imgRect.top,
    containerWidth: imgRect.width,
    targetHeight: loadedImgEl?.naturalHeight ?? imgRect.height,
    targetWidth: loadedImgEl?.naturalWidth ?? imgRect.width,
  })

  const style = Object.assign(
    {},
    styleImgRegular,
  )

  if (isZoomed) {
    let isDesktop = window.matchMedia('(min-width:768px)').matches;
    const viewportX = window.innerWidth / 2
    const viewportY = window.innerHeight / 2

    const childCenterX = parseFloat(String(style.left || 0)) + (parseFloat(String(style.width || 0)) / 2)
    const childCenterY = parseFloat(String(style.top || 0)) + (parseFloat(String(style.height || 0)) / 2)

    const translateX = viewportX - childCenterX
    const translateY = viewportY - childCenterY

    // For scenarios like resizing the browser window
    if (shouldRefresh) {
      style.transitionDuration = '0.01ms'
    }

    if(isDesktop) {
      style.transform = `translate(${translateX}px,${translateY}px) scale(1)`
    } else {
      style.opacity = `0`
    }
  }

  return style
}

export const getImgRegularStyle = ({
  containerHeight,
  containerLeft,
  containerTop,
  containerWidth,
  targetHeight,
  targetWidth,
}) => {
  const ratio = targetWidth / targetHeight;
  let isDesktop = window.matchMedia('(min-width:768px)').matches;

  const zoomedHeight = isDesktop ? window.innerHeight - 180 : window.innerHeight - 372;
  const zoomedWidth = window.innerWidth;

  const hScale = zoomedHeight / containerHeight;
  const wScale = zoomedWidth / containerWidth;

  let width = isDesktop ? containerWidth * hScale : containerWidth * wScale;
  let height = isDesktop ? containerHeight * hScale : containerHeight * wScale;
  let scale = isDesktop ? hScale : wScale;

  return {
    objectFit: "contain",
    top: containerTop,
    left: containerLeft,
    width: width,
    height: height,
    opacity: "1",
    transform: `translate(0,0) scale(${1 / scale})`,
  }
}