import React from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { SectionGutters } from 'components/Section/Section';
import MenuItem from 'components/MenuItem/MenuItem';
import ZoomImage from 'components/ZoomImage/ZoomImage';
import ZoomItem from 'components/ZoomItem/ZoomItem';
import ZoomImageCarousel from 'modules/ZoomImageCarousel/ZoomImageCarousel';
import Zoom from 'react-medium-image-zoom'
import { media } from 'utils/mediaQuery';



const HalfGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  column-gap: 2px;
  row-gap: 2px;
  width: 100%;
  grid-column: 1 / span 3;

  ${media.small`
    grid-template-columns: repeat(6,1fr);
    grid-column: span 6;
    column-gap: 20px;
    row-gap: 20px;
  `}
`

const HalfTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column: 1 / span 3;
  grid-row: 1;
  position: sticky;
  top: 0;

  ${media.small`
    justify-content: flex-end;
    grid-column: span 6;
    grid-row: auto;
    position: relative;
  `}
`

const Wrapper = styled.div`
  position: relative;

  ${HalfGrid} {
    padding: 0 0 12px;
  }

  &:last-child ${HalfGrid} {
    padding: 0 0 80px;
  }
    
    
  
  ${media.small`
    & ${HalfGrid} {
      padding: 20px 0 0;
    }
    
    

    &:last-child ${HalfGrid} {
      padding: 20px 0;
    }
  `}
`

const MainGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);
  column-gap: 10px;
  row-gap: 10px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(12,1fr);
    column-gap: 20px;
    row-gap: 20px;
  `}
`



const TitleWrapper = styled.div`
  padding: 20px 0 0;
  position: relative;

  ${media.small`
    position: sticky;
    top: 0;
  `}
`

const BackgroundHover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  background: ${props => props.color};
  opacity: 0;
  transition: opacity .2s ease;
  pointer-events: none;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;

  &:hover > ${BackgroundHover} {
    opacity: 0.4;
  }
`

const ImageInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: auto;

  & > [data-rmiz],
  & > [data-rmiz] > [data-rmiz-content] {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`

const IndexProject = ({node,pIndex,allProjects}) => {

  return (
    <Wrapper>
      <SectionGutters>
        <MainGrid>
          <HalfGrid>
            {node && node.images && node.images.map((image, i) => {
              return (
                <ZoomItem node={image} thumbnail={node.thumbnails[i]} projects={allProjects} pIndex={pIndex} index={i}/>
              )
            })}
          </HalfGrid>
          <HalfTitle>
            <TitleWrapper>
              <MenuItem num={node.subtitle}>{node.title}</MenuItem>
            </TitleWrapper>
          </HalfTitle>
        </MainGrid>
      </SectionGutters>
    </Wrapper>
  )
}

export default IndexProject;
