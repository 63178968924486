import React, { Component, createRef } from 'react';
import styled from 'styled-components';


const Wrapper = styled.dialog`
  &, &[open] {
    position: fixed;
    width: 100%;
    height: calc((var(--vh,1vh) * 100)) !important;
    max-width: none;
    max-height: none;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
  }
  
  &::backdrop {
    display: none;
  }
`

const Dialog = React.forwardRef(({children, ...rest},ref) => {
  return (
    <Wrapper data-keyboard={false} ref={ref} {...rest}>
      {children}
    </Wrapper>
  )
})

export default Dialog;


const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: ${props => props.show ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0)"};
  transition: background .2s ease;
`

const DialogOverlay = ({...rest}) => {

  return (
    <Overlay {...rest}/>
  )
}

export { DialogOverlay }
