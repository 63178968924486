import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import Dialog, { DialogOverlay } from './Dialog';
import TargetEl from './TargetEl';
import ModalImg from './ModalImg';
import { getStyleModalImg } from './utils';

import { createPortal } from 'react-dom'


const CustomImage = styled.img`
      transition: transform 0.3s, opacity 0.3s;
`
// =============================================================================

let elDialogContainer;

if (typeof document !== 'undefined') {
  elDialogContainer = document.createElement('div')
  elDialogContainer.setAttribute('data-rmiz-portal', '')
  document.body.appendChild(elDialogContainer)
}

const ModalState = {
  LOADED: 'LOADED',
  LOADING: 'LOADING',
  UNLOADED: 'UNLOADED',
  UNLOADING: 'UNLOADING',
}

const defaultBodyAttrs = {
  overflow: '',
  width: '',
}


class ZoomSensation extends Component {

  constructor() {
    super();

    this.state = {
      id: '',
      isZoomImgLoaded: false,
      modalState: ModalState.UNLOADED,
      loadedImgEl: undefined,
      shouldRefresh: false,
      shouldFade: false
    }
    this.prevBodyAttrs = defaultBodyAttrs;
    this.styleModalImg = null;
    this.changeObserver = null;
    this.imgEl = null;
    this.imgElObserver = null;
    this.refModalImg = createRef();
    this.refContent = createRef();
    this.refWrap = createRef();
    this.refDialog = createRef();
    this.blockEsc = createRef(false);
  }
  

  render() {
    const { 
      imgEl,
      refContent,
      refDialog,
      refModalImg,
      refWrap,
      blockEsc,
      prevBodyAttrs,
      props: {
        children,
        isZoomed,
        ZoomContent,
        allImages,
        projects,
        pIndex,
        index
      },
      state: {
        id,
        isZoomImgLoaded,
        modalState,
        loadedImgEl,
        shouldRefresh,
        shouldFade
      }
    } = this;
    const isDesktop = typeof window !== "undefined" && window.matchMedia('(min-width:768px)').matches;
    const imgSrc = imgEl ? imgEl.currentSrc : null
    const imgAlt = imgEl ? imgEl.alt : undefined
    const imgSizes = imgEl ? imgEl.sizes : undefined
    const imgSrcSet = imgEl ? imgEl.srcset : undefined

    const hasImage = imgEl && loadedImgEl &&
      window.getComputedStyle(imgEl).display !== 'none'

    const isModalActive = modalState === ModalState.LOADING ||
      modalState === ModalState.LOADED

    const styleContent = {
      opacity: modalState === ModalState.UNLOADED || !isDesktop || shouldFade ? '1' : '0',
      transition: !isDesktop ? "opacity .4s ease" : "",
      cursor: "pointer"
    }

    this.styleModalImg = hasImage
      ? getStyleModalImg({
        isZoomed: isZoomed && isModalActive,
        shouldRefresh,
        loadedImgEl,
        targetEl: imgEl,
      })
      : {}

    const dataOverlayState =
      modalState === ModalState.UNLOADED || modalState === ModalState.UNLOADING
        ? 'hidden'
        : 'visible'

    let modalContent = null

    if (hasImage) {
      const modalImg = <CustomImage
          alt={imgAlt}
          sizes={imgSizes}
          src={imgSrc}
          srcSet={imgSrcSet}
          data-rmiz-modal-img=""
          ref={refModalImg}
          height={this.styleModalImg.height || undefined}
          style={this.styleModalImg}
          width={this.styleModalImg.width || undefined}
        />

        modalContent = ZoomContent ? <ZoomContent modalState={modalState} img={modalImg} allImages={allImages} projects={projects} index={index} pIndex={pIndex} shouldFade={this.shouldFadeExecute} unZoom={this.handleUnzoom}/> : <>{modalImg}</>
    }

   

    return (
      <div ref={refWrap}>
        <div ref={refContent} style={styleContent}>
          {children}
        </div>
        {elDialogContainer != null && createPortal(
          <Dialog ref={refDialog} data-rmiz-modal="" role="dialog">
            {modalContent}
            <DialogOverlay show={modalState === ModalState.UNLOADED || modalState === ModalState.UNLOADING ? false: true}/>
          </Dialog>
        , elDialogContainer)}
      </div>
    )
  }

  componentDidMount() {
    this.setAndTrackImg();
  }

  componentDidUpdate(prevProps) {
    this.handleIfZoomChanged(prevProps.isZoomed)
  }

  shouldFadeExecute = () => {
    this.setState({ shouldFade: true })
  }

  handleDialogClick = (e) => {
    this.handleUnzoom()
  }

  setAndTrackImg = () => {
    const contentEl = this.refContent.current;
    if (!contentEl) return

    this.imgEl = contentEl.querySelector(
      ':is(img, svg, [role="img"], [data-zoom]):not([aria-hidden="true"])'
    )

    if(this.imgEl) {
      this.changeObserver?.disconnect?.()
      this.imgEl?.addEventListener?.('click', this.handleZoom)

      if (!this.state.loadedImgEl) {

        this.handleImgLoad()
      }

      this.imgElObserver = new ResizeObserver(entries => {
        const entry = entries[0]

        if (entry?.target) {
          this.imgEl = entry.target
          this.setState({}) // Force a re-render
        }
      })

      this.imgElObserver.observe(this.imgEl)

    } else if (!this.changeObserver) {
      this.changeObserver = new MutationObserver(this.setAndTrackImg)
      this.changeObserver.observe(contentEl, { childList: true, subtree: true })
    }
  }

  handleImgLoad = () => {
    const { imgEl } = this
    const imgSrc = imgEl ? imgEl.currentSrc : null
    // Comment out the requirement for imgSrc having to be set to do an imageLoader. Needs to be looked into
    //if (!imgSrc) return

    const img = new Image()
    img.sizes = imgEl.sizes
    img.srcset = imgEl.srcset
    

    // img.src must be set after sizes and srcset
    // because of Firefox flickering on zoom
    img.src = imgSrc

    const setLoaded = () => {
      this.setState({ loadedImgEl: img })
    }

    img
      .decode()
      .then(setLoaded)
      .catch(() => { img.onload = setLoaded })
  }

  handleIfZoomChanged = (prevIsZoomed) => {
    const { isZoomed } = this.props

    if (!prevIsZoomed && isZoomed) {

      this.zoom();
    } else if (prevIsZoomed && !isZoomed) {
      this.unzoom();
    }
  }

  handleZoom = () => {
    this.props.onZoomChange?.(true)
  }

  handleUnzoom = () => {
    console.log("unzoom");
    this.props.onZoomChange?.(false)
  }

  handleResize = () => {
    this.setState({ shouldRefresh: true })
  }

  onEsc = (event) => {
    event.preventDefault();

    if(!this.blockEsc.current) {
      this.handleUnzoom()
    }
      
  }

  zoom = () => {
    this.bodyScrollDisable();
    this.refDialog.current?.showModal?.()
    this.setState({ modalState: ModalState.LOADING })
    this.blockEsc.current = true;
    this.refDialog.current?.addEventListener('cancel', this.onEsc);
    this.refModalImg.current?.addEventListener?.('transitionend', this.handleZoomEnd, { once: true })

  }

  unzoom = () => {
    this.setState({ modalState: ModalState.UNLOADING })
    this.blockEsc.current = true;
    this.refModalImg.current?.addEventListener?.('transitionend', this.handleUnzoomEnd, { once: true })
    this.refDialog.current?.removeEventListener('cancel', this.onEsc);
  }

  handleZoomEnd = () => {
    setTimeout(() => {
      this.setState({ modalState: ModalState.LOADED })
      this.blockEsc.current = false;
      window.addEventListener('resize', this.handleResize, { passive: true })
    }, 0)
  }

  handleUnzoomEnd = () => {
    setTimeout(() => {
      window.removeEventListener('resize', this.handleResize)
      this.blockEsc.current = false;
      this.setState({
        shouldRefresh: false,
        shouldFade: false,
        modalState: ModalState.UNLOADED,
      })

      this.refDialog.current?.close?.()
      this.bodyScrollEnable()

    }, 0)
  }

  // ===========================================================================
  // Enable / disable body scrolling

  bodyScrollDisable = () => {
    this.prevBodyAttrs = {
      overflow: document.body.style.overflow,
      width: document.body.style.width,
    }

    // Get clientWidth before setting overflow: 'hidden'
    const clientWidth = document.body.clientWidth

    document.body.style.overflow = 'hidden'
    document.body.style.width = `${clientWidth}px`
  }

  bodyScrollEnable = () => {
    document.body.style.width = this.prevBodyAttrs.width
    document.body.style.overflow = this.prevBodyAttrs.overflow
    this.prevBodyAttrs = defaultBodyAttrs
  }

}

export default ZoomSensation;