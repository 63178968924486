import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import ZoomSensation from 'components/ZoomSensation/ZoomSensation';
import ZoomImageCarousel from 'modules/ZoomImageCarousel/ZoomImageCarousel';



const Cover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.color || `rgb(var(--brand-black))`};
  z-index: 1;
  opacity: 0;
  transition: opacity .2s ease;
  pointer-events: none;
`

const Wrapper = styled.div`
  position: relative;

  &:hover ${Cover}{
    opacity: 0.4;
  }
`

const CustomImage = styled(GatsbyImage)`
  position: relative;
  width: 100%;
  height: 100%;
`

const ZoomItem= ({node,thumbnail, pIndex, index, imagesReference, projects}) => {
  const imageObject = thumbnail.asset ? getImage(thumbnail.asset) : null;
  const [isHover,setIsHover] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false)

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

 return (
  <Wrapper>
    <Cover color={imageObject && imageObject.backgroundColor}/>
    <ZoomSensation isZoomed={isZoomed} onZoomChange={handleZoomChange} ZoomContent={ZoomImageCarousel} allImages={imagesReference} projects={projects} pIndex={pIndex} index={index}>
      <GatsbyImage 
        image={imageObject}
        alt={node.alt}
        sizes='(max-width: 800px) 500px, (max-width: 1200px) 800px, (max-width: 1920px) 900px, (max-width: 2520px) 1000px, (min-width: 2521px) 1200px'
        backgroundColor={imageObject.backgroundColor}
        style={{width: "100%"}} />
    </ZoomSensation>
  </Wrapper>
 )
}

export default ZoomItem;