import React, { Component, createRef } from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
  position: relative;
  width: 60%;
  height: 100%;
`

const TargetEl = React.forwardRef(({children, ...rest},ref) => {
  return (
    <Wrapper ref={ref} {...rest}>
      {children}
    </Wrapper>
  )
})

export default TargetEl;
