import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.img`
  position: absolute;
  cursor: zoom-out;
  image-rendering: high-quality;
  transform-origin: top left;
  transition: transform 0.3s;
`

const ModalImg = (props) => {
  return (
    <Wrapper {...props}/>
  )
}

export default ModalImg;

